@import 'common/generic';

$footer-height: 55px;
// Need to set height to 100% for correct footer placement
html,
body,
#app,
.main-body {
  height: 100%;
}

body {
  background-color: white;
  font-family: Roboto;
  font-weight: $fe-fontweight-regular;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .main-body {
    width: 100%;

    .content-wrapper {
      min-height: 100%;
      margin-bottom: -$footer-height;

      &.first-page {
        background-color: #f6f6f6;
      }
    }

    .white-link {
      color: white;
      text-decoration: none;

      @extend .hover;
      &:hover,
      &:focus,
      &:visited {
        color: $fe-grey-text;
      }

      &.first-page {
        &:hover,
        &:focus,
        &:visited {
          color: black;
        }
      }
    }
  }
}

.content-wrapper:after {
  content: '';
  display: block;
}

.content-wrapper:after {
  height: $footer-height;
}

a {
  color: black;

  &.visited {
    color: black;
  }

  &.yellow-link {
    color: $fe-yellow;
    text-decoration: none;

    &:hover,
    &:focus,
    &:visited {
      color: $fe-yellow;
    }
  }
}

.hide-desktop {
  @include breakpoint(desktop) {
    display: none;
  }
}

.hide-mobile {
  display: none;

  @include breakpoint(desktop) {
    display: inherit;
  }
}

.hidden {
  display: none !important;
}

.page-container {
  margin: 0 auto;
  max-width: $fe-max-page-width;
}

.page-banner {
  position: relative;
  text-align: center;
  background: #ffffff;
  line-height: 52px;
  min-height: 52px;
  color: black;
  @extend .small-box-shadow;
}
