@import 'common/generic';

.newsletter-form {
  .newsletter-content {
    padding: 16px 16px 30px 16px;
    border-bottom: 1px solid $fe-border-grey;

    @include breakpoint(sm) {
      padding: 10px 50px 50px 50px;
    }

    .newsletter-info {
      line-height: 1.8;
    }

    .newsletter-input {
      margin-bottom: 10px;
    }

    .newsletter-agree {
      padding-top: 20px;
    }

    .agree-text {
      margin-left: 10px;
    }
  }

  .submit-button {
    margin: 18px auto;
    display: block;
  }
}

.newsletter-success {
  padding-left: 10px;
}
