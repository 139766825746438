@import 'common/generic';

.login-form {
    .login-content {
        padding: 16px 16px 30px 16px;
        border-bottom: 1px solid $fe-border-grey;

        @include breakpoint(sm) {
            padding: 10px 50px 50px 50px;
        }

        .login-info {
            line-height: 1.8;
        }

        .account-question {
            margin-bottom: 35px;
            font-weight: 500;
        }

        .login-input {
            margin-bottom: 10px;
        }
    }

    .submit-button {
        margin: 18px auto;
        display: block;
    }
}
