@import 'common/generic';

.error-popup {
    top: 30%!important;
}

.popup-box {
    position: fixed;
    top: 5%;
    left: 50%;
    z-index: 101;
    border-radius: 10px;
    background-color: white;
    padding: 10px 0 5px;

    width: 300px;
    margin-left: -150px;

    .button {
        padding: 10px 25px;
        margin-bottom: 5px;
    }

    @include breakpoint(tablet) {
        width: 400px;
        margin-left: -200px;
    }

    .popup-title {
        font-weight: bold;
        font-size: 110%;
        text-align: center;
    }

    .popup-secondary-button {
        background-color: white;
        border: 1px solid black;
        color: black;
    }

    .popup-centered-button {
        padding: 15px;
        text-align: center;

        .button {
            min-width: 90%;
        }
    }

    .popup-message-indicator {
        border: 1px solid black;
        border-radius: 50%;
        text-align: center;
        display: inline-block;
        width: 30px;
        height: 30px;
        padding: 1px;
        position: absolute;
    }

    .popup-next-to-indicator {
        font-size: 85%;
        margin-left: 40px;
        display: inline-block;
    }

    .popup-box-content,
    .popup-footer {
        padding: 15px;
        width: 100%;
        max-width: 100%;
    }

    .left-text {
        text-align: left;
        white-space: initial;
        line-height: 26px;
    }

    .popup-footer {
        text-align: right;
    }

    .popup-close {
        float: right;
        padding-right: 16px;
        cursor: pointer;

        .close-icon {
            width: 30px;
            height: 30px;
        }
    }
}
