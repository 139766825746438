@import 'common/generic';

.register {
  .register-content {
    padding: 16px 16px 30px 16px;
    border-bottom: 1px solid $fe-border-grey;

    @include breakpoint(sm) {
      padding: 10px 50px 50px 50px;
    }

    .register-title {
      color: white;
      font-size: 16px;
      font-weight: $fe-fontweight-regular;
      margin-bottom: 20px;

      @include breakpoint(tablet) {
        font-size: 20px;
      }
    }

    .register-info {
      line-height: 1.8;
    }

    .account-question {
      margin-bottom: 35px;
      font-weight: 500;
    }

    .input-fields {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto);
      grid-gap: 10px 0;

      @include breakpoint(tablet) {
        grid-template-rows: repeat(3, auto);
      }

      .input-username,
      .input-email,
      .input-password {
        grid-column-start: span 2;
      }

      .input-firstname,
      .input-lastname {
        grid-column-start: span 2;

        @include breakpoint(tablet) {
          grid-column-start: span 1;
        }
      }

      .input-lastname {
        @include breakpoint(tablet) {
          .input-block {
            grid-template-columns: auto;
            border-left: 1px solid lightgray;
          }

          .icon {
            display: none;
          }
        }
      }
    }
  }

  .register-success {
    padding: 16px;
    border-bottom: 1px solid $fe-border-grey;

    @include breakpoint(sm) {
      padding: 10px 50px 10px 50px;
    }
  }

  .submit-button {
    margin: 18px auto;
    display: block;
  }

  .skip-button {
    color: white;
  }
  .checkbox {
    margin-top: 22px;
  }

  .privacypolicy {
    margin-left: 5px;
    color: inherit;
  }

  .newsletter {
    margin-left: 5px;
  }
}
