@import 'common/generic';

.lib-header {
  background-color: $fe-background-white;
  height: 75px;
  display: flex;
  align-items: center;
  border-bottom: 6px solid $fe-banner-black;

  .lib-header-logo {
    margin-left: 10px;
    height: 90%;
    cursor: pointer;
  }

  .librarian-menu {
    margin-left: 20px;
    margin-top: 10px;
    max-height: 34px;
    overflow: hidden;

    a {
      cursor: pointer;
      display: block;
      float: left;
      margin: 5px;
      margin-left: 20px;
      text-decoration: none;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .librarian-user {
    font-size: 90%;
    color: #999999;
  }
}

.lib-language {
  padding-right: 20px;
  margin-left: 500px;
  position: relative;
}
