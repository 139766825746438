@import 'common/generic';

.input-container {
    &.light {
        .input-block {
            .icon {
                background-color: $fe-yellow-v2;
                svg {
                    color: black;
                }
            }

            input {
                background-color: white;
            }
        }
        .input-message {
            color: white;
        }
    }

    .input-block {
        height: 56px;
        width: 100%;
        display: flex;

        &.no_icon {
            border-left: 1px solid $fe-card-gradient-bottom;
        }

        .icon {
            background-color: black;
            height: 100%;
            width: 56px;
            padding: 16px;
            svg {
                color: white;
                height: 24px;
            }
        }

        input {
            width: 100%;
            padding-left: 20px;
            font-weight: bold;
            outline: none;
            background-color: $fe-light-grey;
            border: none;

            &:disabled {
                background-color: $fe-background-grey;
                color: $fe-grey-text;
            }

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                font-weight: normal;
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                font-weight: normal;
            }
            &:-ms-input-placeholder {
                /* IE 10+ */
                font-weight: normal;
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                font-weight: normal;
            }
        }
    }
    .input-message {
        width: 100%;
        display: block;
        margin-top: 5px;
        text-align: right;
        color: $fe-grey-text;
        &.error {
            color: red;
        }
    }
}
