@import 'common/generic';

.footer-container {
    background-color: black;
    color: white;
    font-weight: $fe-fontweight-light;
    padding: 30px 0;

    .sm-screen{
        @include breakpoint(sm) {
            display: none;
        }
    }

    a {
        text-decoration: none;
        color: $fe-yellow-v2-muted;
        -webkit-transition: background-color 0.1s ease-in, color 0.1s ease-in;
        -o-transition: background-color 0.1s ease-in, color 0.1s ease-in;
        transition: background-color 0.1s ease-in, color 0.1s ease-in;

        @extend .hover;
        &:hover {
            color: darken($fe-yellow-v2-muted, 4%);
        }
    }

    .footer-info {
        display: inline-block;

        .row-wrapper {
            display: flex;
        }

        .separator {
            padding-left: 7px;
            padding-right: 7px;
        }

        p {
            margin-top: 0;
        }

        @include breakpoint(tablet) {
            margin-right: 32px;
        }
    }

    .footer-emails {
        margin-top: 30px;
        display: inline-block;
        margin-right: 30px;

        @include breakpoint(smallDesktop) {
            float: right;
            margin-top: 0;
            margin-right: 0;
        }
    }

    .footer-help {
        position: fixed;
        z-index: 100;
        bottom: 32px;
        right: 24px;
        background-color: black;
        margin-top: 40px;
        border-radius: 120px;
        padding: 8px;
        font-size: 20px;
        @extend .small-box-shadow;

        img {
            filter: brightness(0) invert(1);
        }

        &:hover {
            cursor: pointer;
            color: $fe-yellow;
            img {
                filter: brightness(1);
            }
        }

        @include breakpoint(xs) {
            display: none;
        }

        @include breakpoint(md) {
            display: inline-block;
        }
    }

    .footer-privacy {
        margin-top: 20px;
        font-size: 14px;
    }
}
