@import "common/generic";

.loading-bar {
    position: fixed;
    top: 0px;
    height: 3px;
    z-index: 99999;
    background-color: $fe-button-yellow;

    &.loading-bar-light {
        background-color: white;
    }
}
