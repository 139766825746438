@import "common/generic";

.front-page-banner-wrapper {
    color: white;
    text-align: center;
    z-index: 9; // lower than the one for compatibility warning
                // (app/customer/App/components/CompatibilityWarning/CompatibilityWarning.scss)

    padding-top: 0.5em;
    padding-bottom: 0.5em;

    &.front-page-banner-info {
        background-color: $fe-button-yellow;
    }

    &.front-page-banner-error {
        background-color: #ff4444;
    }
}
