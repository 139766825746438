@import "common/generic";

// TOP ROW
#header-top-row-container {
    z-index: 5;
}

.header-top-row {
    height: 76px;
    display: grid;
    justify-content: space-between;
    white-space: nowrap;
    grid-template-columns: 80px 130px 60px;

    @include breakpoint(sm) {
        grid-template-columns: 130px 200px 65px;
    }

    @include breakpoint(md) {
        grid-template-columns: calc(50% - 100px) 200px calc(50% - 100px);
    }

    .header-cineasterna-logo {
        margin-top: 16px;
        margin-bottom: 16px;
        width: 130px;
        @extend .hover;
    }

    .header-cineasterna-logo-mobile {
        @extend .hover;
        width: 80px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .header-library-selector {
        z-index: 5;
        display: grid;
        grid-template-rows: 8px auto 8px;
        position: relative;

        > * {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        .header-library-logo {
            justify-self: center;
            max-width: 130px;
            max-height: 60px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-right: 24px;
            cursor: pointer;
            background: url('./assets/arrow-select.png') no-repeat right 5px top 50%;

            @include breakpoint(sm) {
                max-width: 200px;
            }
        }
    }

    .header-controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        line-height: 76px;
        max-width: 100%;

        .language-selector-wrapper {
          padding: 0;
        }

        .blue-text {
            color: $fe-blue;
            white-space: nowrap;
            vertical-align: middle;

            @include breakpoint(lg) {
                display: inline-block;
            }
        }

        .header-account {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .header-account-circle {
            width: 32px;
            height: 32px;
            margin-right: 8px;
        }

        .header-user-controls {
            max-width: 100%;
            padding-left: 12px;
            cursor: pointer;
            display: inline-block;
            line-height: 32px;

            @include breakpoint(md) {
                max-width: calc(100% - 65px);
            }
        }

        .dots {
            width: 36px;
            @extend .hover;

            &:hover {
                filter: brightness(0.4);
            }
        }

        .dots-menu {
            position: absolute;
            margin-left: -50px;
            background-color: white;
            padding: 10px;
            border-radius: 5px;
            border: 1px solid #CACACA;
            z-index: 5;
            -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);
            box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);
            text-align: right;
            text-align: left;

            .dots-menu-row {
                @extend .hover;

                a {
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }
                &:last-child {
                    margin-top: 5px;
                    padding-top: 5px;
                    border-top: 1px solid #ccc;
                }
            }
        }
    }
}

.header-container {
    background-color: white;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    z-index: 4;
    @extend .small-box-shadow;

    .container {
        padding: 0;
    }

    // NOTIFICATION
    .header-notification {
        background-color: $fe-blue;
        color: white;
        text-align: center;
        font-weight: lighter;
        padding: 12px;
    }

    .grey-bottom-border {
        left: 0;
        right: 0;
        height: 1px;
        width: 100%;
        background-color: #DDDDDD;
    }

    .header-close-icon {
        width: 20px;
        filter: brightness(0) invert(1);
        margin-left: 8px;
        margin-top: -4px;
        @extend .hover;

        &:hover {
            filter: invert(1) brightness(0.9);
        }
    }

    .scrollable {
        padding: 0;
        position: relative;

        @include breakpoint(xs) {
            overflow-x: scroll;
        }

        @include breakpoint(sm) {
            overflow-x: auto;
        }
    }

    // MID ROW
    .header-mid-row {
        padding-top: 8px;
        padding-bottom: 8px;
        min-width: 200px;
        display: grid;
        grid-template-columns: auto auto auto auto;

        .active > a {
            color: $fe-blue;
        }

        .active > a > img {
            filter: brightness(1);
        }

        .header-nav-link {
            order: 2;
            display: inline-block;
            text-align: center;
            line-height: 40px;

            a {
                @extend .hover-yellow;
            }
        }

        .search-link {
            cursor: default;
            order: 1;

            @include breakpoint(sm) {
                order: 2;
            }

            a {
                height: 40px;
                cursor: pointer;
            }

            img {
                filter: brightness(0);
            }

            @extend .hover;

            img:hover {
                filter: brightness(1);
            }
        }
    }

    .hide-upcoming {
        grid-template-columns: auto auto auto !important;
    }
}
