@use 'sass:math';

// COLORS:
$fe-yellow-v2-muted: #cfb644;
$fe-yellow-v2: #f1c70e;
$fe-yellow: #d9af00;
$fe-yellow-dark: #98863c;
$fe-grey-text: #969696;
$fe-dark-grey: #46494c;
$fe-light-grey: #f5f5f5;
$fe-card-gradient-top: #40617e;
$fe-card-gradient-bottom: #344271;

$fe-blue: #0059ff;
$fe-light-blue: #ebf4fe;
$fe-button-yellow: #ac973d;
$fe-button-dark-yellow: #98863c;
$fe-border-blue: #628ce3;
$fe-border-yellow: #d8c47a;
$fe-border-grey: #dddddd;
$fe-background-grey: #eeecee;
$fe-banner-black: black;
$fe-background-white: #f6f6f6;
$fe-card-gradient-top: #dce4e5;
$fe-card-gradient-bottom: #d2dbe2;

// FONTS:
$fe-fontweight-medium: 700;
$fe-fontweight-regular: 400;
$fe-fontweight-light: 300;

// DIMENSIONS:
$fe-max-page-width: 1200px;
$title-image-size: 224px;
$title-image-margin: 8px;

// Gradients backgrounds: (top-down)
@mixin background-topdown-gradient($topColor, $bottomColor) {
  background: $topColor; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient($topColor, $bottomColor); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient($topColor, $bottomColor); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient($topColor, $bottomColor); /* For Firefox 3.6 to 15 */
  background: linear-gradient($topColor, $bottomColor); /* Standard syntax */
}

// BREAKPOINTS:
$breakpoint-desktop: 992px;
$breakpoint-small-desktop: 600px;
$breakpoint-tablet: 480px;
$breakpoint-mobile: 400px;
$breakpoint-titleDisplay: 630px;
$breakpoint-col2: ($title-image-size + $title-image-margin * 2) * 2 + 20px;
$breakpoint-col3: ($title-image-size + $title-image-margin * 2) * 3 + 20px;
$breakpoint-col4: ($title-image-size + $title-image-margin * 2) * 4 + 20px;
$breakpoint-col5: ($title-image-size + $title-image-margin * 2) * 5 + 20px;
$breakpoint-xs: 0px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

@mixin breakpoint($point) {
  @if ($point == pageContainer) {
    @media (min-width: $fe-max-page-width) {
      @content;
    }
  } @else if ($point == desktop) {
    @media (min-width: $breakpoint-desktop) {
      @content;
    }
  } @else if ($point == smallDesktop) {
    @media (min-width: $breakpoint-small-desktop) {
      @content;
    }
  } @else if ($point == tablet) {
    @media (min-width: $breakpoint-tablet) {
      @content;
    }
  } @else if ($point == mobile) {
    @media (min-width: $breakpoint-mobile) {
      @content;
    }
  } @else if ($point == col2) {
    @media (min-width: $breakpoint-col2) {
      @content;
    }
  } @else if ($point == col3) {
    @media (min-width: $breakpoint-col3) {
      @content;
    }
  } @else if ($point == col4) {
    @media (min-width: $breakpoint-col4) {
      @content;
    }
  } @else if ($point == col5) {
    @media (min-width: $breakpoint-col5) {
      @content;
    }
  } @else if ($point == titleDisplay) {
    @media (min-width: $breakpoint-titleDisplay) {
      @content;
    }
  } @else if ($point == xs) {
    @media (min-width: $breakpoint-xs) {
      @content;
    }
  } @else if ($point == sm) {
    @media (min-width: $breakpoint-sm) {
      @content;
    }
  } @else if ($point == md) {
    @media (min-width: $breakpoint-md) {
      @content;
    }
  } @else if ($point == lg) {
    @media (min-width: $breakpoint-lg) {
      @content;
    }
  } @else if ($point == xl) {
    @media (min-width: $breakpoint-xl) {
      @content;
    }
  }
}

html {
  max-width: 100vw;
  overflow-x: hidden;
}

select {
  outline: none;
}

.block-shadow {
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);
}

.card-shadow {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.block-shadow-beneath {
  -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.15);
}

.big-block-shadow-beneath {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
}

.button {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 25px;
  color: white;
  font-size: 15px;
  background-color: $fe-button-yellow;
  border: 1px solid $fe-border-yellow;
  outline: none;

  @include breakpoint(tablet) {
    padding: 10px 25px;
    margin-bottom: 5px;
  }

  @extend .hover;
  &:hover {
    background-color: $fe-button-dark-yellow;
  }
}

.large-input {
  display: block;
  outline: none;
  width: 100%;
  border: 1px solid #bbbbbb;
  border-radius: 25px;
  padding: 10px;
  margin-top: 5px;
}

.total-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  z-index: 2;

  &.dark {
    background-color: #555;
    z-index: 5;
  }

  &.darker {
    background-color: #111;
    opacity: 0.8;
    z-index: 5;
  }
}

.text-center {
  text-align: center;
}

.clear {
  clear: both;
}

.hide-xs {
  display: none;

  @include breakpoint(sm) {
    display: initial;
  }
}

.show-xs {
  display: initial;

  @include breakpoint(sm) {
    display: none;
  }
}

.hide-sm {
  display: none;

  @include breakpoint(md) {
    display: initial;
  }
}

.show-sm {
  display: initial;

  @include breakpoint(md) {
    display: none;
  }
}

.hover {
  cursor: pointer;
  -webkit-transition: all 0.1s ease-in, color 0.1s ease-in;
  -o-transition: all 0.1s ease-in, color 0.1s ease-in;
  transition: all 0.1s ease-in, color 0.1s ease-in;
}

.hover-yellow {
  @extend .hover;
  &:hover {
    color: $fe-yellow !important;
  }
}

.hover-blue {
  @extend .hover;
  &:hover {
    color: $fe-blue;
  }
}

.small-box-shadow {
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.2);
}

.title-card-box-shadow {
  -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  @extend .hover;

  &:hover {
    -webkit-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.3), 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  }
}

// Container styling

// Specify the width of the gutters.
$grid-gutter-width: 30px !default;

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
) !default;

// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@mixin make-container() {
  width: 100%;
  padding-right: math.div($grid-gutter-width, 2);
  padding-left: math.div($grid-gutter-width, 2);
  margin-right: auto;
  margin-left: auto;
}

// Minimum breakpoint width. Null for the smallest (first) breakpoint.
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
// Makes the @content apply to the given breakpoint and wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

.container {
  @include make-container();
  @include make-container-max-widths();
}

.container-fluid {
  @include make-container();
}
