@import 'common/generic';

.language-selector-wrapper {
    background-color: white;
    border-radius: 25% / 50%;
    display: inline;
    padding: 6px;
    overflow: hidden;
}

.language-selector {
    text-transform: capitalize;
    color: black;
    background-color: transparent;
    font-weight: $fe-fontweight-regular;
    border: 0;
    outline: none;

    .lang-option {
        padding-left: 5px;
        padding-right: 5px;
        @extend .hover-yellow;
    }
}
