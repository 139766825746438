@import 'common/generic';

.modal {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    max-width: 680px;
    max-height: 100vh;
    overflow-y: auto;
    background: rgb(255, 255, 255);
    overflow: auto;
    -webkit-overflow-scrolling: 'touch';
    border-radius: 4px;
    outline: none;
    z-index: 4;

    .title-bar {
        display: grid;
        grid-template-columns: auto 52px;
        height: 48px;
        border-bottom: 1px solid $fe-border-grey;
        .title {
            font-weight: bold;
            margin: 16px 15px;
        }
        .close-icon {
            @extend .hover;
            width: 100%;
            height: 100%;
            padding: 12px 14px;
            align-self: center;
            justify-self: center;
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
}

.delete-confirmation-content {
    padding-left: 20px;
    padding-bottom: 20px;

    .button-cancel {
        margin-left: 5px;
        border-radius: 3px;
    }
}

