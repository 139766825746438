@import 'common/generic';

.forgot-password {
    .forgot-password-content {
        padding: 16px 16px 30px 16px;
        border-bottom: 1px solid $fe-border-grey;

        @include breakpoint(sm) {
            padding: 10px 50px 50px 50px;
        }

        .forgot-password-info {
            line-height: 1.8;
        }

        .email-input {
            margin-bottom: 10px;
        }
    }

    .submit-button {
        margin: 18px auto;
        display: block;
    }
}
