@import 'common/generic';

.button-v2 {
    @extend .hover;
    outline: none;
    border: none;
    transition: background-color 0.2s ease;

    &.xs {
        font-size: 12px;
    }

    &.sm {
        min-width: 200px;
        padding: 10px 50px;
        font-size: 14px;
        font-weight: 500;
    }

    &.md {
        padding: 10px 50px;
        font-size: 16px;
        font-weight: 500;
    }

    &.lg {
        padding: 15px 40px;
        font-size: 18px;
        font-weight: 500;

        @include breakpoint(tablet) {
            padding: 15px 75px;
        }
    }

    &.primary {
        border-radius: 4px;
        background-color: $fe-yellow-v2;
        color: black;

        &:hover {
            background-color: $fe-yellow;
        }

        &:disabled {
            background-color: $fe-grey-text;
        }

        /* TODO: &.action, &.success, &.warning, &.danger */
    }

    /* TODO: &.secondary */

    &.link {
        padding: 0;
        margin: 0;
        min-width: unset;
        background-color: transparent;
        border: none;
        color: $fe-blue;

        &:hover {
            background-color: transparent;
            text-decoration: underline;
        }

        &:disabled {
            color: $fe-grey-text;
        }

        &.action {
            color: $fe-yellow-v2-muted;
        }

        &.danger {
            color: red;
        }

        /* TODO: &.success, &.warning */
    }
}
