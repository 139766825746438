@import "common/generic";

.loader-wrapper {
    padding-top: 150px;

    @include breakpoint(tablet) {
        padding-top: 300px;
    }

    &.small-padding {
        padding-top: 50px;
    }
}

.loader {
    font-size: 10px;
    margin: 0px auto 60px auto;
    text-indent: -9999em;
    width: 5em;
    height: 5em;
    border-radius: 50%;
    background: $fe-banner-black;
    background: -moz-linear-gradient(
        left,
        $fe-banner-black 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
        left,
        $fe-banner-black 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
        left,
        $fe-banner-black 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
        left,
        $fe-banner-black 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
        to right,
        $fe-banner-black 10%,
        rgba(255, 255, 255, 0) 42%
    );
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}

.loader:before {
    width: 50%;
    height: 50%;
    background: $fe-banner-black;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
}

.loader:after {
    background: $fe-background-grey;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: "";
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.inverted {
    background: $fe-background-white;
    background: -moz-linear-gradient(
        left,
        $fe-background-white 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -webkit-linear-gradient(
        left,
        $fe-background-white 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -o-linear-gradient(
        left,
        $fe-background-white 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: -ms-linear-gradient(
        left,
        $fe-background-white 10%,
        rgba(255, 255, 255, 0) 42%
    );
    background: linear-gradient(
        to right,
        $fe-background-white 10%,
        rgba(255, 255, 255, 0) 42%
    );
}

.inverted:before {
    background: $fe-background-white;
}

.inverted:after {
    background: $fe-banner-black;
}

@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
