@import "common/generic";

.push-down {
    top: 50px !important;
}

.non-supported-browser-notice {
    background-color: #000000;
    color: #FFFFFF;
    text-align: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    z-index: 10;
}
