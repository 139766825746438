@import "common/generic";

.user-menu {
    position: absolute;
    margin-left: -30px;
    background-color: white;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #CACACA;
    z-index: 5;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .25);


    right: 30px;
    @include breakpoint(xl) {
        right: unset;
    }

    .user-menu-row {
        min-width: 110px;
        cursor: pointer;
        margin-top: 1px;
        margin-bottom: 1px;

        a {
            text-decoration: none;
        }

        &:hover {
            text-decoration: underline;
        }
    }
}

